import React, { useState } from 'react';
import './Cities.css'; // Assuming you have a separate CSS file named Cities.css for styling
import { useNavigate } from 'react-router-dom';

// List of cities
const cities = [
  "Bangalore", "Delhi", "Chennai", "Hyderabad", "Mumbai", "Pune", "Kolkata", "Ahmedabad",
  "Amritsar", "Bhopal", "Bhubaneswar", "Chandigarh", "Faridabad", "Ghaziabad", "Jamshedpur",
  "Jaipur", "Kochi", "Lucknow", "Kanpur", "Nagpur", "Patna", "Raipur", "Surat", "Visakhapatnam", 
  "Agra", "Ajmer", "Mysuru", "Srinagar", "Etawah", "Roorkee", "Rajamundry", "Bhatinda",
  "Hajipur", "Rohtak", "Hosur", "Gandhinagar", "Junagadh", "Udaipur", "Salem", "Jhansi",
  "Madurai", "Vijaywada", "Meerut", "Mathura", "Bikaner", "Cuttack", "Nashik",
  "Ayodhya", "bareilly", "Fatehpur-Sikri", "Unnao", "Hardoi", "Varanasi", "Barabanki", 
  "Noida", "Chitrakoot-Uttar-Pradesh", "Prayagraj", "Coimbatore", "Indore", 
  "Thiruvananthapuram", "Guwahati", "Vadodara", "Ranchi", "Ludhiana", 
  "Puducherry", "Jamnagar", "Warangal", "Kota", "Bhilai", "Kalyan-Dombivli", 
  "Vasai-Virar", "Aurangabad", "Dhanbad", "Amravati", "Allahabad", "Howrah", 
  "Rajkot", "Gwalior", "Jabalpur", "Bhilwara", "Haridwar", "Kakinada", "Belgaum", 
  "Jammu", "Ujjain", "Tirunelveli", "Malegaon", "Davangere", "Kozhikode", "Akola", 
  "Kurnool", "Mangalore", "Ambattur", "Tiruppur", "Moradabad", "Gorakhpur", 
  "Bhavnagar", "Dehradun", "Kollam", "Nanded", "Gulbarga", "Satna", "Chandrapur", 
  "Latur", "Durg", "Sangli-Miraj & Kupwad", "Hapur", "Bihar Sharif", "Panvel", 
  "Parbhani", "Ichalkaranji", "Tumkur", "Bathinda", "Rampur", "Shimoga", "Hisar", 
  "Fatehpur", "Shivamogga", "Chandannagar", "Hospet", "Hindupur", "Sikar", 
  "Bhagalpur", "Bellary", "Maheshtala", "Sultan Pur Majra", "Tirupati", "Bijapur", 
  "Raiganj", "Udupi", "Patiala", "Pathankot", "Betul", "Ramagundam", "Pali", 
  "Khammam", "Avadi", "Baranagar", "Dindigul", "Adoni", "Aizawl", "Bardhaman", 
  "Krishnanagar", "Yamunanagar", "Behrampur", "Bally", "Medininagar", "Munger", 
  "Ratlam", "Sambalpur", "Purnia", "Giridih", "Muzaffarpur", "Kottayam", "Kadapa", 
  "Karimnagar", "Bhimavaram", "Shimla", "Rewa", "New Delhi", "Gurgaon", 
  "Greater Noida", "Thane", "Virar", "Mira-Bhayandar", "Bhiwandi", "Pimpri-Chinchwad", 
  "Solapur", "Kolhapur", "Ichalkaranji", "Ahmednagar", "Satara", "Malegaon", "Dhule", 
  "Jalgaon", "Latur", "Nanded", "Parbhani", "Wardha", "Gondiya", "Bhusawal", 
  "Jalna", "Baramati", "Osmanabad", "Nandurbar", "Washim", "Hingoli", "Yavatmal", 
  "Gadchiroli", "Buldhana", "Pune", "Virar", "Mira-Bhayandar"
];

const Cities = ({ serviceName, serviceNameWithSpace }) => {
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSelectedCity(value);
    setFilteredCities(getFilteredCities(value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCity) {
      navigate(`/services/${serviceName}-Photographers/${selectedCity}`);
      window.scrollTo(0, 0);
    }
  };

  const getFilteredCities = (value) => {
    const inputValue = value.trim().toLowerCase();
    if (inputValue.length === 0) {
      return [];
    }

    const exactMatch = cities.filter(city => city.toLowerCase() === inputValue);
    if (exactMatch.length > 0) {
      return exactMatch;
    }

    const startsWithInput = cities.filter(city =>
      city.toLowerCase().startsWith(inputValue)
    );

    const includesInput = cities.filter(city =>
      city.toLowerCase().includes(inputValue) && !city.toLowerCase().startsWith(inputValue)
    );

    return [...startsWithInput, ...includesInput].slice(0, 10);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setFilteredCities([]);
  };

  return (
    <div className="cities-container">
      <h2>If you are looking for {serviceNameWithSpace} Photographers in your City</h2>
      <form onSubmit={handleSubmit} className='city-input-container'>
        <input
          type="text"
          placeholder="Select your city"
          value={selectedCity}
          onChange={handleInputChange}
          className="city-input"
        />
        {filteredCities.length > 0 && (
          <div className="suggestions-dialog">
            {filteredCities.map(city => (
              <div
                key={city}
                className="suggestion-item"
                onClick={() => handleCityClick(city)}
              >
                {city}
              </div>
            ))}
          </div>
        )}
        <button className='checkout-button' type="submit">Search</button>
      </form>
    </div>
  );
};

export default Cities;
