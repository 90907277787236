import React, { useState } from 'react';
import './RecommendationsForm.css'; // Import your CSS file

const RecommendationsForm = (serviceName) => {
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [fullEmail, setFullEmail] = useState('@gmail.com');
  const [message, setMessage] = useState('basic');
  const [service, setService] = useState(serviceName.serviceName || "");
  const [city, setCity] = useState('');
  const [budget, setBudget] = useState('xyz');
  const [fullNameError, setFullNameError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    if (e.target.value.trim() === '') {
      setFullNameError(true);
    } else {
      setFullNameError(false);
    }
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
    if (e.target.value.trim() === '') {
      setMobileNumberError(true);
    } else {
      setMobileNumberError(false);
    }
  };

  const handleEmailChange = (e) => {
    setFullEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleServiceChange = (e) => {
    setService(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleBudgetChange = (e) => {
    setBudget(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fullName.trim() === '') {
      setFullNameError(true);
    }
    if (mobileNumber.trim() === '') {
      setMobileNumberError(true);
    }
    
    try {
      const response = await fetch('/quotation', {
        method: 'POST',
        body: JSON.stringify({ fullName, mobileNumber, fullEmail, message, service, city, budget }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        alert('You Will Be Contacted Within 5 minutes');
        setFullName('');
        setMobileNumber('');
        setFullEmail('');
        setMessage('');
        setService('');
        setCity('');
        setBudget('');
      } else {
        alert('Registration failed');
      }
    } catch (error) {
      alert('Something went wrong, please try again later.');
    }
  };

  return (
    <div className="recommendations-container">
      <h2 style={{ textAlign: "left" }}>Get Best Recommendations & Deals on your phone</h2>
      <form className="formatting" onSubmit={handleSubmit}>
        <input type="text" placeholder="Full Name" value={fullName} onChange={handleFullNameChange} required />
        {fullNameError && <span className="error-message">Name is required</span>}

        <input type="text" placeholder="Mobile Number" value={mobileNumber} onChange={handleMobileNumberChange} required />
        {mobileNumberError && <span className="error-message">Mobile no. is required</span>}

        {/* <input type="email" placeholder="Enter your Email ID" value={fullEmail} onChange={handleEmailChange} />

        <input type="text" placeholder="Enter some message" value={message} onChange={handleMessageChange} /> */}

              <div className="form-group">
              {/* <label htmlFor="service">Service</label> */}
              <input
                type="text"
                id="service"
                name="service"
                value={service}
                onChange={(e) => setService(e.target.value)}
                placeholder="Enter the service"
                required
              />
            </div>
        <input type="text" placeholder="Enter your city" value={city} onChange={handleCityChange} required />

        {/* <select className='selectbut' value={budget} onChange={handleBudgetChange} required>
          <option value="">Select your budget</option>
          <option value="10000-20000">₹10,000 - ₹20,000</option>
          <option value="20000-30000">₹20,000 - ₹30,000</option>
          <option value="30000-40000">₹30,000 - ₹40,000</option>
          <option value="40000-50000">₹40,000 - ₹50,000</option>
          <option value="50000-60000">₹50,000 - ₹60,000</option>
          <option value="60000+">₹60,000+</option>
        </select> */}

        <button className="rec-button" type="submit">Submit</button>
      </form>

      <p style={{ textAlign: "left" }}>
        <img style={{ marginRight: "5px" }} height="20" viewBox="0 0 32 32" width="20" src="/phone.svg" alt="phone-svg" />
        WhatsApp/Call with your personal Photography Manager
      </p>

      <p style={{ textAlign: "left" }}>
        <img style={{ marginRight: "5px" }} height="20" viewBox="0 0 32 32" width="20" src="/th.jpg" alt="th.jpg" />
        Tell her your doubts, requirements, budget and get best recommendations
      </p>

      <p style={{ textAlign: "left" }}>
        <img style={{ marginRight: "5px" }} height="20" viewBox="0 0 32 32" width="20" src="/assure.jpg" alt="assure.jpg" />
        Get the best deal in your budget and plan!
      </p>
    </div>
  );
};

export default RecommendationsForm;
