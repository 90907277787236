import React, { useState } from 'react';
import './SideMenu.css';

const SideMenu = ({ services, onServiceClick }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleServiceClick = (service) => {
    onServiceClick(service.id); // Trigger any additional logic
    window.location.href = `/services/${service.serviceNam}-Photographers`; // Navigate to the URL and reload
  };

  return (
    <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
      <ul className="service-list">
        {services.map((service) => (
          <li
            key={service.id}
            className="service-item"
            onClick={() => handleServiceClick(service)}
          >
            {service.serviceNameWithSpace}
          </li>
        ))}
      </ul>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default SideMenu;
