import React, { useState, useEffect } from 'react';
import './GetInTouch.css'; // Import your CSS file
import OfferCard from './PopupAd'; // Import OfferCard component

const GetInTouch = (serviceName) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State to control popup visibility

  // Form state
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [fullEmail, setFullEmail] = useState('@gmail.com');
  const [message, setMessage] = useState('basic');
  const [service, setService] = useState(serviceName.serviceName || ""); 
  const [city, setCity] = useState('');
  const [budget, setBudget] = useState('xyz');
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPopupVisible(true);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Clear timer when component unmounts
  }, []);

  // Handle form visibility (Only opens the form, does not toggle)
  const handleOpenShutter = () => {
    setIsOpen(true);  // Ensure this only opens the shutter
  };

  // Handle closing both the shutter and the popup
  const handleCloseShutter = () => {
    setIsOpen(false);  // Close shutter
    setIsPopupVisible(false);  // Close popup as well
  };

  // Handle form field changes
  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);
  const handleEmailChange = (e) => setFullEmail(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);
  const handleServiceChange = (e) => setService(e.target.value);
  const handleCityChange = (e) => setCity(e.target.value);
  const handleBudgetChange = (e) => setBudget(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/quotation', {
        method: 'POST',
        body: JSON.stringify({ fullName, mobileNumber, fullEmail, message, service, city, budget }),
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (response.ok) {
        const data = await response.json();
        alert('You will be contacted within 5 minutes');
        // Reset form fields
        setFullName('');
        setMobileNumber('');
        setFullEmail('');
        setMessage('');
        setService('');
        setCity('');
        setBudget('');
      } else {
        const errorData = await response.json();
        alert(`Registration failed: ${errorData.error}`);
      }
    } catch (error) {
      alert('Something went wrong, please try again later.');
    }
  };

  return (
    <div className="get-in-touch-container">
      {/* Render the OfferCard component only if the popup is visible */}
      {isPopupVisible && <OfferCard handleClose={handleCloseShutter} serviceName={serviceName}/>}  {/* Pass handleOpenShutter */}

      {isOpen && (
        <div className={`shutter ${isOpen ? 'open' : ''}`}>
          {/* Close both popup and shutter */}
          <button className="close-button" onClick={handleCloseShutter}>✖</button>
          <div className="form-container-touch">
            <h3><strong>Talk to an Expert</strong></h3>
            <p>Enter your details, we will contact you</p>
            <form onSubmit={handleSubmit}>
              <input type="text" placeholder="Enter your full name" value={fullName} onChange={handleFullNameChange} required />
              <input type="number" placeholder="Enter your phone number" value={mobileNumber} onChange={handleMobileNumberChange} required />
              {/* <input type="email" placeholder="Enter your Email ID" value={fullEmail} onChange={handleEmailChange} /> */}
              {/* <input type="text" placeholder="Enter some message" value={message} onChange={handleMessageChange} /> */}
              
              <div className="form-group">
              {/* <label htmlFor="service">Service</label> */}
              <input
                type="text"
                id="service"
                name="service"
                value={service}
                onChange={(e) => setService(e.target.value)}
                placeholder="Enter the service"
                required
              />
            </div>

              <input type="text" placeholder="Enter your city" value={city} onChange={handleCityChange} required />

             {/* <select className='selectbut' value={budget} onChange={handleBudgetChange} required>
          <option value="">Select your budget</option>
          <option value="10000-20000">₹10,000 - ₹20,000</option>
          <option value="20000-30000">₹20,000 - ₹30,000</option>
          <option value="30000-40000">₹30,000 - ₹40,000</option>
          <option value="40000-50000">₹40,000 - ₹50,000</option>
          <option value="50000-60000">₹50,000 - ₹60,000</option>
          <option value="60000+">₹60,000+</option>
        </select>*/}

              <button type="submit">Contact us</button>
            </form>
          </div>
        </div>
      )}

      <div className="get-in-touch-content">
        {/* <img className="get-in-touch-content-img" viewBox="0 0 32 32" src="/diff.png" alt="get-in-touch-png" /> */}
        <div className="get-in-touch-para">
          <p className="get-in-touch-para-p1"><strong>Limited-Time Offer: Book Your Photography Now and Get 40% OFF!</strong></p>
          {/* <p className="get-in-touch-para-p2"><strong onClick={handleOpenShutter}>{"Get in touch"}</strong>{">"}</p> */}
          <button className="checkout-button" onClick={handleOpenShutter}>
          <strong>Get in touch</strong>
          <span>{'>'}</span>
        </button>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
