import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../UserContext";
import axios from 'axios';

const QuotationTable = () => {
  const { setUserInfo, userInfo } = useContext(UserContext);
  const [quotations, setQuotations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/profile', {
      credentials: 'include',
    }).then(response => {
      response.json().then(userInfo => {
        setUserInfo(userInfo);
      });
    });
  }, [setUserInfo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/getquotation');
        const sortedData = response.data.sort((a, b) => b.id - a.id);
        setQuotations(sortedData);
      } catch (error) {
        console.error('Error fetching quotations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const phone = userInfo?.phone;
  const current_date = new Date().toISOString().slice(0, 10); // Format current date as YYYY-MM-DD

  return (
    <div>
      {phone !== '0980987878' && (
        <div style={{ width: "100vw", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <h1>Not Admin</h1>
        </div>
      )}
      {phone === '0980987878' && (
        <>
          <h2>Quotation Table</h2>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Full Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Service</th>
                  <th>City</th>
                  <th>Budget</th>
                  <th>Message</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {quotations.map(quotation => (
                  <tr key={quotation.id}>
                    <td>{quotation.id}</td>
                    <td>{quotation.fullname}</td>
                    <td>{quotation.phone}</td>
                    <td>{quotation.email}</td>
                    <td>{quotation.service}</td>
                    <td>{quotation.city}</td>
                    <td>{quotation.budget}</td>
                    <td>{quotation.message}</td>
                    {quotation.created_at.slice(0, 10) === current_date ? (
                      <td style={{ backgroundColor: "cyan" }}>{quotation.created_at.slice(0, 10)}</td>
                    ) : (
                      <td>{quotation.created_at.slice(0, 10)}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default QuotationTable;
