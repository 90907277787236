import React from 'react';
import './AboutC.css'; // Import CSS file for styling
import { Helmet } from 'react-helmet';
import Footer from './Footer';

const AboutC = () => {
  
  return (
    <div className="about-container">
      <Helmet>
      <meta name="description" content="FotoShoot is an online platform that transforms your cherished memories into reality through captivating imagery"/>
      <link rel="canonical" href="https://fotoshoot.in/aboutus"/>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11016491595"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11016491595');
          `}
        </script>
      </Helmet>
      <h2 className="section-heading">About Us</h2>
      <div className="about-content">
        <p className="paragraph">
          With over 15 years of experience in the field of photography and videography, Captured Moments with Fotoshoot.in stands as a symbol of excellence in capturing life's most precious moments in a stunning and captivating way. Our journey began with a passion for storytelling through visual arts, and today, we are proud to be a leading name in the world of photography and videography.
        </p>
        <h3 className="sub-heading">Our Story</h3>
        <p className="paragraph">
          Our story is one of passion, dedication, and a deep love for the art of capturing moments. Over the past 15 years, we have grown from a humble beginning into a full-fledged photography and video production company with a wide range of services that cater to various needs and preferences.
        </p>
        <h3 className="sub-heading">Our Mission</h3>
        <p className="paragraph">
        At Captured Moments, our mission is simple yet profound: To freeze time and preserve emotions in the most beautiful and authentic way possible. We understand that life is an incredible journey filled with unforgettable moments, both big and small. Our goal is to capture these moments with an artistry that transcends the ordinary and becomes timeless.
What Sets Us Apart
•	Unrivalled Expertise: Our extensive experience has given our team a depth of knowledge and skills that are unmatched in the industry. Our photographers and videographers are not just skilled professionals; they are artists who have a keen eye for detail and a deep understanding of the art of storytelling. We believe that every image and video we create should not just capture a moment but tell a story.
•	Cutting-Edge Technology: To create stunning visuals, it's crucial to stay updated with the latest technology. With over 15 years in the industry, we've continually invested in cutting-edge equipment and software to ensure our clients get the best quality. Whether it's a wedding, a corporate event, or a family portrait, our equipment guarantees outstanding results.
•	Tailored Solutions: We recognize that no two clients are the same, and every project is unique. Whether you are looking for wedding photography, corporate videos, or family portraits, we work closely with you to understand your vision and deliver results that exceed your expectations.
•	Strong Client Relationships: Our clients are at the heart of everything we do. We believe in building strong relationships and listening to your desires. It's our pleasure to work closely with you, from the initial consultation to the final delivery, ensuring that your vision is brought to life.
Our Services
•	Wedding Photography & Videography: Your wedding day is a once-in-a-lifetime event, and with over 15 years of experience, we understand the importance of capturing every smile, every tear, and every joyous moment. Our wedding photography and videography services are designed to make your special day truly magical.
•	Corporate Events: Whether it's a product launch, a conference, or a team-building event, our corporate event services are tailored to help you showcase your business at its best. We create videos and photos that capture the essence of your brand and message.
•	Family Portraits: Families are the centre of our lives, and our family portrait sessions aim to preserve the love, laughter, and bonds that make your family unique. We create heart-warming, candid images that you'll cherish for generations.
•	Commercial Photography & Videography: In the competitive world of business, image is everything. Our commercial services, with over 15 years of experience, help businesses shine by producing visually compelling content for marketing, advertising, and promotional purposes.
•	Special Occasions: From birthdays to anniversaries and everything in between, our team, backed by years of experience, is ready to capture the joy of your special occasions, so you can relive the moments for years to come.

Why Choose Us?
When you choose Captured Moments with Fotoshoot.in, you're choosing a team with over 15 years of experience that is passionate about creating unforgettable memories. Here's why you should choose us:
•	Excellence in Every Shot: We're committed to excellence, and it shows in every photograph and video we produce. Our attention to detail, creative approach, and dedication to perfection ensure you receive outstanding results every time.
•	A Team of Artists: We're not just photographers and videographers; we're artists with a profound love for our craft. Every project is an opportunity to create something extraordinary.
•	Affordability: We believe that everyone should have access to top-quality photography and videography. Our pricing is competitive and designed to cater to various budgets.
•	Quick Turnaround: We understand that you're excited to see the results. Our efficient workflow ensures that you receive your images and videos in a timely manner without compromising on quality.
Our Commitment to Quality
Quality is the cornerstone of our business. We take immense pride in delivering products that are not just good but exceptional. Our commitment to quality is reflected in every aspect of our work:
•	Stunning Visuals: Our team combines artistic vision with technical expertise to capture visuals that are not just sharp and clear, but also full of emotion and meaning. We believe in creating images and videos that speak to your heart.
•	Cutting-Edge Editing: Post-production is where the magic happens. Our experienced editors use state-of-the-art software to enhance and refine the raw footage, turning it into a work of art. Whether it's colour correction, sound editing, or adding special effects, our editing team knows how to make your project shine.
•	Diverse Styles: We understand that every client has unique preferences. That's why we offer a wide range of styles, from classic and timeless to modern and innovative. Whether you want a vintage feel or a sleek contemporary look, we can tailor our work to suit your tastes.
•	End-to-End Solutions: We handle every aspect of your project, from initial concept and planning to the final product delivery. This ensures a seamless and stress-free experience for you. Our team coordinates all the logistical details so that you can focus on enjoying the moment.
•	A Passion for Storytelling: At the heart of our work is a deep passion for storytelling. Every photograph and video we create is a narrative that captures not just the visual elements but also the emotions and atmosphere of the moment. We believe in the power of storytelling to transport you back in time and make you relive the joy, love, and excitement of that special occasion.
•	Our Team: Our team is the backbone of our success. We are a diverse group of photographers, videographers, editors, and creative professionals who share a common love for visual storytelling. Each member of our team brings a unique perspective and skill set, contributing to the depth and richness of our work. Together, we are dedicated to creating experiences that our clients cherish for a lifetime.
Join Our Journey
At Captured Moments, we're not just a company; we're a community of artists, dreamers, and storytellers with over 15 years of experience. We invite you to join our journey and experience the magic of every moment through our lens. Whether you're planning a wedding, a corporate event, or simply want to capture your family's joy, we're here to help you create memories that last a lifetime.
Unlock the magic of every moment with Captured Moments Photography & Video Productions. Contact us today and let us tell your story through our lens.

        </p>
      </div>
      <Footer/>
    </div>
  );
}
export default AboutC;