import React, { useState, useEffect } from "react";
import "./PopupAd.css";

const OfferCard = ({ handleClose, serviceName }) => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [service, setService] = useState(serviceName.serviceName || ""); // Default to `serviceName`
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [fullEmail, setFullEmail] = useState('@gmail.com');
  const [message, setMessage] = useState('basic');
  const [city, setCity] = useState('');
  const [budget, setBudget] = useState('example');
  // console.log(serviceName)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCloseButton(true);
    }, 10000); // Show close button after 20 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/quotation", {
        method: "POST",
        body: JSON.stringify({ fullName, mobileNumber, fullEmail, message, service, city, budget }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        const data = await response.json();
        alert("You will be contacted within 5 minutes");
        // Reset form fields
        setFullName('');
        setMobileNumber('');
        setFullEmail('');
        setMessage('');
        setCity('');
        setBudget('');
        setService(serviceName || "");
        handleClose()
      } else {
        const errorData = await response.json();
        alert(`Submission failed: ${errorData.error}`);
      }
    } catch (error) {
      alert("Something went wrong, please try again later.");
    }
  };

  return (
    <div className="offer-card">
      {/* Close Button */}
      {showCloseButton && (
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
      )}

      <div className="offer-content">
        <div className="image-section">
          <img
            src="/images/Banner, Popup, Recommendation Images/Photoshoot Popup form.webp"
            alt="Photoshoot Offers"
            className="offer-image"
          />
        </div>
        <div className="form-section">
          <h2>Limited-Time Offer: Get 40% OFF</h2>
          {serviceName.serviceName !== "BirthdayShoot"&&serviceName.serviceName !== "BabyShoot"&&<p>
            Consult with our expert and receive a 40% discount on your first {serviceName.serviceName} photoshoot. Let us capture your special moments in a unique
            and artistic way.
          </p>}
          {serviceName.serviceName === "BirthdayShoot"||serviceName.serviceName === "BabyShoot"&&<p>
            Consult with our expert and receive a 40% discount on your first {serviceName.serviceName}. Let us capture your special moments in a unique
            and artistic way.
          </p>}
          <form className="offer-form" onSubmit={handleSubmit}>
            <div className="form-group">
              {/* <label htmlFor="name">Name</label> */}
              <input
                type="text"
                id="name"
                name="name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="phone">Phone</label> */}
              <input
                type="tel"
                id="phone"
                name="phone"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="city">City</label> */}
              <input
                type="text"
                id="city"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter your city"
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="service">Service</label> */}
              <input
                type="text"
                id="service"
                name="service"
                value={service}
                onChange={(e) => setService(e.target.value)}
                placeholder="Enter the service"
                required
              />
            </div>
            <button className="submit-btn" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
